/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.1.1/dist/jquery.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /npm/select2@4.0.3/dist/js/select2.min.js
 * - /npm/nprogress@0.2.0/nprogress.min.js
 * - /npm/datatables.net@1.10.16/js/jquery.dataTables.min.js
 * - /npm/datatables.net-bs@1.10.16/js/dataTables.bootstrap.min.js
 * - /npm/datatables.net-responsive@2.2.0/js/dataTables.responsive.min.js
 * - /npm/moment@2.8.4/moment.min.js
 * - /npm/datatables.net-plugins@1.10.15/sorting/datetime-moment.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
